import React from 'react'
// import { Link } from 'gatsby'
import { MainLayout } from '../components/common'
import { Row, Col, Typography, Result } from 'antd'
const { Title } = Typography
import { MehTwoTone } from '@ant-design/icons'

const NotFoundPage = () => (
    <MainLayout>
        <Row
            gutter={[16, 24]}
            justify={`space-around`}
        >
            <Col
                xs={24}
                sm={24}
                md={18}
                lg={16}
            >
                <Typography>
                    <Title>
                        Page Not Found
                    </Title>
                </Typography>
            </Col>
        </Row>

        <Row
            gutter={[16, 24]}
            justify={`space-around`}
        >
            <Col
                xs={24}
                sm={24}
                md={18}
                lg={16}
            >
                <Result
                    icon={<MehTwoTone />}
                    title="You're looking a little lost."
                    extra={`That page doesn't seem to exists.`}
                />
            </Col>
        </Row>
    </MainLayout>
)

export default NotFoundPage
